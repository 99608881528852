import { Button } from '@robinpowered/ui-kit';
import ExportIcon from '@robinpowered/ui-kit-icons/Export';
import { useTranslation } from 'react-i18next';
import { useExportTicketsToCSV } from 'hooks/useExportTicketsToCSV';

// There was an issue with our lazy queries calling without the tenantId.
// So do not render this button unless the tenantId is present.
export const ExportCSVButton = ({ tenantId }: { tenantId: string }) => {
  const { t } = useTranslation('TicketsListPage');
  const { exportToCSV, isExporting } = useExportTicketsToCSV();

  const handleExportCSV = () => {
    exportToCSV();
  };

  return (
    <Button
      onClick={handleExportCSV}
      disabled={isExporting || !tenantId}
      icon={<ExportIcon />}
      iconPosition="start"
    >
      {t('table.export_csv')}
    </Button>
  );
};
