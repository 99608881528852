import { useAmplitude } from 'contexts';
import { useEffect } from 'react';

export const TrackServicesTabLoaded = () => {
  const { trackServicesTabLoaded } = useAmplitude();

  useEffect(() => {
    trackServicesTabLoaded();
  }, [trackServicesTabLoaded]);

  return null;
};
