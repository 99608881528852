import React from 'react';
import { DatePicker } from '@robinpowered/ui-kit';
import moment from 'moment-timezone';
import type { Moment } from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { useDatePicker } from './hooks/useDatePicker';
import { useTranslation } from 'react-i18next';

const { RangePicker } = DatePicker.generatePicker<Moment>(momentGenerateConfig);

export interface TicketsDatePickerProps {
  dateRange: { startDate: string; endDate: string };
  setDateRange: (dateRange: { startDate: string; endDate: string }) => void;
  resetDateRange: () => void;
}

export const TicketsDatePicker = ({
  dateRange,
  setDateRange,
  resetDateRange,
}: TicketsDatePickerProps) => {
  const { t } = useTranslation('common');
  const { onRangeChange, dateValues, minimumDate } = useDatePicker({
    dateRange,
    setDateRange,
    resetDateRange,
  });

  return (
    <RangePicker
      minDate={minimumDate}
      value={dateValues}
      presets={[
        {
          label: t('date_picker.next_7_days'),
          value: [
            moment.tz(moment.tz.guess()).add(7, 'd'),
            moment.tz(moment.tz.guess()),
          ],
        },
        {
          label: t('date_picker.next_30_days'),
          value: [
            moment.tz(moment.tz.guess()).add(30, 'd'),
            moment.tz(moment.tz.guess()),
          ],
        },
        {
          label: t('date_picker.next_90_days'),
          value: [
            moment.tz(moment.tz.guess()).add(90, 'd'),
            moment.tz(moment.tz.guess()),
          ],
        },
      ]}
      format="YYYY-MM-DD"
      onChange={onRangeChange}
    />
  );
};
