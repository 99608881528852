import { useMemo } from 'react';
import moment from 'moment';
import {
  useGetEventByIdForMeetingServiceRequestQuery,
  useGetLocationNameDetailsForMeetingServiceRequestQuery,
  useGetUserByIdForMeetingServiceRequestQuery,
} from 'generated';
import { WorkplaceServiceFormRequest } from '@robinpowered/common-lib';
import { useAuthContext } from 'contexts';

export const useGetServiceRequestDetails = (
  initialValues: WorkplaceServiceFormRequest | null
) => {
  const eventType = initialValues?.event.type;
  const { loading: authLoading } = useAuthContext();
  const { data: eventData, loading: loadingEventData } =
    useGetEventByIdForMeetingServiceRequestQuery({
      variables: {
        id:
          eventType === 'existing' && initialValues?.event.id
            ? initialValues?.event.id
            : '',
      },
      skip: eventType !== 'existing' || authLoading,
    });

  const { data: locationData, loading: loadingLocation } =
    useGetLocationNameDetailsForMeetingServiceRequestQuery({
      variables: {
        locationId: initialValues?.locationId ?? '',
        levelId: initialValues?.levelId ?? '',
        spaceId: initialValues?.spaceId ?? '',
        hasLevelId: !!initialValues?.levelId,
      },
      skip:
        !initialValues?.locationId ||
        !initialValues?.levelId ||
        !initialValues?.spaceId ||
        authLoading,
    });

  const locationName = useMemo(() => {
    return [
      locationData?.getLevelById?.name,
      locationData?.getSpaceById?.name,
      locationData?.getLocationById?.name,
    ]
      .filter((x) => x)
      .join(', ');
  }, [locationData]);

  const { data: userData, loading: loadingUserData } =
    useGetUserByIdForMeetingServiceRequestQuery({
      variables: { id: initialValues?.userId || '' },
      skip: !initialValues?.userId || authLoading,
    });

  const dueDate = useMemo(
    () =>
      eventType === 'draft'
        ? moment(initialValues?.event.startTime).tz(
            initialValues?.event.timezone || moment.tz.guess()
          )
        : moment(eventData?.getEventById?.startTime),
    [eventData?.getEventById?.startTime, eventType, initialValues?.event]
  );

  const numOfAttendees = useMemo(() => {
    return eventType === 'draft'
      ? initialValues?.event.numAttendees || 0
      : eventData?.getEventById?.invitees.length || 0;
  }, [
    eventData?.getEventById?.invitees.length,
    eventType,
    initialValues?.event,
  ]);

  return {
    requestedBy: userData?.getUserById
      ? {
          name: userData.getUserById.name,
          avatar: userData.getUserById.avatar,
        }
      : undefined,
    //@TODO: implement this with the category select dropdown
    assignedTo: [],
    dueDate: dueDate.format('ddd, MMM DD, h:mm a'),
    locationName,
    numOfAttendees,
    loading:
      loadingEventData || loadingUserData || !initialValues || authLoading,
    loadingLocation: loadingLocation,
    initialValues,
  };
};
