import React, { createContext, useContext, useMemo } from 'react';
import {
  GetBuildingsWhereUserCanManageMeetingServicesForFormQuery,
  GetLocationsByOrgIdQuery,
  useGetBuildingsWhereUserCanManageMeetingServicesForFormQuery,
  useGetLocationsByOrgIdQuery,
} from 'generated';
import { useAuthContext } from 'contexts';

type ManageMeetingServicesLocationType = {
  organizationLocations: NonNullable<
    GetLocationsByOrgIdQuery['getOrganizationById']
  >['locations'];
  locationsUserCanManage: NonNullable<
    GetBuildingsWhereUserCanManageMeetingServicesForFormQuery['listBuildingsWhereUserCanManageMeetingServices']
  >['buildings'];
  loading: boolean;
};

const ManageMeetingServicesLocationContext =
  createContext<ManageMeetingServicesLocationType>({
    organizationLocations: [],
    locationsUserCanManage: [],
    loading: false,
  });

export const ManageMeetingServicesLocationProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const { currentOrg, loading: authLoading } = useAuthContext();
  const { data: buildingData, loading: loadingMeetingServiceManageBuildings } =
    useGetBuildingsWhereUserCanManageMeetingServicesForFormQuery({
      variables: {},
      skip: authLoading,
    });
  const { data: orgData, loading: loadingOrg } = useGetLocationsByOrgIdQuery({
    variables: {
      id: currentOrg?.id || '',
    },
    skip: authLoading,
  });

  const organizationLocations = useMemo(() => {
    return orgData?.getOrganizationById?.locations || [];
  }, [orgData?.getOrganizationById?.locations]);

  const locationsUserCanManage = useMemo(() => {
    return (
      buildingData?.listBuildingsWhereUserCanManageMeetingServices?.buildings ||
      []
    );
  }, [buildingData]);

  return (
    <ManageMeetingServicesLocationContext.Provider
      value={{
        organizationLocations,
        locationsUserCanManage,
        loading:
          loadingMeetingServiceManageBuildings || loadingOrg || authLoading,
      }}
    >
      {children}
    </ManageMeetingServicesLocationContext.Provider>
  );
};

export const useManageMeetingServicesLocationContext =
  (): ManageMeetingServicesLocationType => {
    return useContext(ManageMeetingServicesLocationContext);
  };
