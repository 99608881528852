import styled from '@emotion/styled';
import { Avatar, Typography } from '@robinpowered/ui-kit';
import UserSolid from '@robinpowered/ui-kit-icons/UserSolid';

export const Assignee = ({
  name,
  avatar,
  label,
  email,
}: {
  name?: string;
  email?: string;
  avatar?: string | null;
  label?: string;
}) => {
  return (
    <AssigneeWrapper>
      {avatar ? (
        <Avatar src={avatar} name={name} size={26}>
          <UserSolid />
        </Avatar>
      ) : name ? (
        <Avatar name={name} size={26} />
      ) : (
        <Avatar size={26} icon={<UserSolid />} />
      )}
      <AssigneeInfo>
        <Typography.Text>{label}</Typography.Text>
        {email && (
          <Typography.Text color="textSecondary">{email}</Typography.Text>
        )}
      </AssigneeInfo>
    </AssigneeWrapper>
  );
};

const AssigneeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AssigneeInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;
