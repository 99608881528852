/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Empty,
  Flex,
  Table as RobinTable,
  Typography,
} from '@robinpowered/ui-kit';
import { Pagination } from './components/Pagination/Pagination';
import styled from '@emotion/styled';
import {
  TicketsListTableContextProvider,
  useTicketsListTableContext,
} from './contexts';
import { useTicketsListPageContext } from 'pages/TicketsListPage/contexts/TicketsListPageContext';
import { Header } from './Header';
import { useRef } from 'react';
import { useFixAntTableHeight } from 'hooks/useFixAntTableHeight';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { SelectLocations } from 'components/common/SelectedLocations/SelectLocations';
import { TicketsDatePicker } from '../common/DatePicker/DatePicker';
import { useManageMeetingServicesLocationContext } from 'contexts/LocationsContext';
import ExportIcon from '@robinpowered/ui-kit-icons/Export';
import { ExportCSVButton } from './components/ExportCSVButton';
import { useApolloContext } from 'contexts';

const TicketsListTableContainer = () => {
  const { tenantId } = useApolloContext();
  const {
    selectedLocationIds,
    setSelectedLocationIds,
    dateRange,
    setDateRange,
    resetDateRange,
    handleSetFiltersForMeetingServiceRequestsQuery,
    handleSetSortByForListServiceRequestsQuery,
    handleClearAllFilters,
    filtersAreApplied,
  } = useTicketsListPageContext();

  const { loading: loadingLocations } =
    useManageMeetingServicesLocationContext();
  const { tableData, columns, loadingTicketsListTable, setSkipItems } =
    useTicketsListTableContext();

  const [searchParams] = useSearchParams();
  const serviceRequestKey = searchParams.get('serviceRequestKey');

  const contentRef = useRef<HTMLDivElement>(null);
  const tableHeight = useFixAntTableHeight(contentRef, {
    headerHeight: 110,
    footerHeight: 75,
  });
  const { t } = useTranslation('TicketsListPage');

  const tableCanRender = !loadingTicketsListTable && tableData.length > 0;
  // Fixes a bug with ant table: https://github.com/ant-design/ant-design/issues/26621
  const scroll = tableCanRender
    ? {
        x: 'max-content',
        y: tableHeight,
      }
    : { x: 1750 };

  return (
    <TableWrapper ref={contentRef}>
      <Header title={t('header')} />
      <Flex gap={16} wrap="wrap" justify="space-between">
        <Flex gap={16} wrap="wrap" flex={1}>
          <SelectLocations
            onSelectLocation={setSelectedLocationIds}
            selectedLocations={selectedLocationIds}
            loading={loadingLocations}
          />
          <TicketsDatePicker
            dateRange={dateRange}
            setDateRange={setDateRange}
            resetDateRange={resetDateRange}
          />
        </Flex>
        {tenantId ? (
          <ExportCSVButton tenantId={tenantId} />
        ) : (
          // Show a fake button until the tenantId is available to prevent
          // errors we experienced with lazy queries. See useExportTicketsToCSV.tsx for more info
          <Button disabled={true} icon={<ExportIcon />} iconPosition="start">
            {t('table.export_csv')}
          </Button>
        )}
      </Flex>
      <RobinTable
        sticky={tableCanRender && { offsetHeader: 0 }}
        scroll={scroll}
        dataSource={loadingTicketsListTable ? [] : tableData}
        columns={columns}
        style={{ alignSelf: 'flex-start', flex: 1, width: '100%' }}
        pagination={false}
        loading={loadingTicketsListTable}
        // @TODO: If we ever use bulk selection on this page we will have to consolidate
        // the row selection here with the built in selection from ant tables
        rowSelection={{
          hideSelectAll: true,
          type: 'radio',
          selectedRowKeys: serviceRequestKey ? [serviceRequestKey] : undefined,
        }}
        onChange={(_, filters, sorter) => {
          handleSetFiltersForMeetingServiceRequestsQuery(filters);
          handleSetSortByForListServiceRequestsQuery(sorter);

          setSkipItems(0);
        }}
        locale={{
          emptyText: (
            <Empty description={null}>
              <Flex vertical align="center" justify="center">
                <Typography.Text>{t('table.empty.no_data')}</Typography.Text>
                {filtersAreApplied && (
                  <>
                    <Typography.Text
                      type="secondary"
                      style={{ marginBottom: 16 }}
                    >
                      {t('table.empty.no_data_subheader')}
                    </Typography.Text>
                    <Button
                      onClick={() => {
                        handleClearAllFilters();
                        resetDateRange();
                      }}
                    >
                      {t('table.empty.clear_filters')}
                    </Button>
                  </>
                )}
              </Flex>
            </Empty>
          ),
        }}
      />
      <PaginationWrapper>
        <Pagination />
      </PaginationWrapper>
    </TableWrapper>
  );
};

export const TicketsListTable = () => {
  return (
    <TicketsListTableContextProvider>
      <TicketsListTableContainer />
    </TicketsListTableContextProvider>
  );
};

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const TableWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;

  // Fix to for antdesign td's where we cant access their styles easily
  td {
    vertical-align: middle;
  }

  // @TODO: Remove this style if we decide to utilize ant built in selection
  .ant-table-selection-column .ant-radio-wrapper {
    display: none;
  }
`;
