import {
  createContext,
  useContext,
  FC,
  ReactNode,
  useMemo,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import {
  GetMeetingServicesForAdminTableListQuery,
  InputMaybe,
  ListMeetingServicesFiltersInput,
  ListMeetingServicesSortByInput,
  useGetMeetingServicesCountForTablePaginationQuery,
  useGetMeetingServicesForAdminTableListQuery,
} from 'generated';
import { useTenantLocalStorage } from 'hooks/useTenantLocalStorage';
import { useManageMeetingServicesLocationContext } from 'contexts/LocationsContext';

const SELECTED_LOCATIONS = 'selected-locations';

type RefetchFilters = {
  skip?: InputMaybe<number> | undefined;
  after?: string | null | undefined;
};
type MeetingServicesValue = {
  meetingServicesData: GetMeetingServicesForAdminTableListQuery | undefined;
  refetchMeetingServices: (filters?: RefetchFilters) => void;
  meetingServicesCount: number;
  refetchMeetingServicesCount: () => void;
  loading: boolean;
  filtersForMeetingServicesQuery: ListMeetingServicesFiltersInput | undefined;
  setFiltersForMeetingServicesQuery: Dispatch<
    SetStateAction<ListMeetingServicesFiltersInput | undefined>
  >;
  sortByForMeetingServicesQuery:
    | ListMeetingServicesSortByInput
    | undefined
    | null;
  setSortByForMeetingServicesQuery: Dispatch<
    SetStateAction<ListMeetingServicesSortByInput | undefined | null>
  >;
  selectedLocationIds: string[];
  setSelectedLocationIds: (value: string[]) => void;
};

const MeetingServicesContext = createContext<MeetingServicesValue>({
  meetingServicesData: undefined,
  refetchMeetingServices: () => null,
  meetingServicesCount: 0,
  refetchMeetingServicesCount: () => null,
  loading: false,
  filtersForMeetingServicesQuery: undefined,
  setFiltersForMeetingServicesQuery: () => null,
  sortByForMeetingServicesQuery: undefined,
  setSortByForMeetingServicesQuery: () => null,
  selectedLocationIds: [],
  setSelectedLocationIds: () => null,
});

type Props = {
  children: ReactNode;
};

export const ITEMS_PER_TABLE_PAGE = 15;

export const MeetingServicesContextProvider: FC<Props> = ({ children }) => {
  // TODO: if user does not have permission to manage services in any building
  // then we should redirect away or show error message
  const { loading: loadingLocations, locationsUserCanManage } =
    useManageMeetingServicesLocationContext();

  const [selectedLocationIds = [], setSelectedLocationIds] =
    useTenantLocalStorage<string[]>(SELECTED_LOCATIONS, []);

  useEffect(() => {
    // If the local storage is empty, then we want to set the default
    // selected locations to the locations the user can manage
    if (
      !loadingLocations &&
      locationsUserCanManage?.length > 0 &&
      selectedLocationIds.length === 0
    ) {
      setSelectedLocationIds(
        locationsUserCanManage.map((location) => location?.id || '')
      );
    }
    // We rely on the initial selectedLocationIds length to be 0 on first render
    // so that we can set a default value for the selected locations
    // we do not want this to run every time selectedLocationIds changes so we omit in the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationsUserCanManage, setSelectedLocationIds, loadingLocations]);

  const availableInAnyBuildingIdsOrTheirDescendants = useMemo(() => {
    // If user has selected locations, use them, otherwise we made a product decision
    // if a user has nothing selected, show all the locations
    const locations =
      selectedLocationIds && selectedLocationIds.length > 0
        ? selectedLocationIds
        : locationsUserCanManage.map((option) => option?.id || '');

    return locations.filter((location) => !!location);
  }, [selectedLocationIds, locationsUserCanManage]);

  const [filtersForMeetingServicesQuery, setFiltersForMeetingServicesQuery] =
    useTenantLocalStorage<ListMeetingServicesFiltersInput>(
      'meeting-service-admin-table-filters',
      {
        belongsToAnyCategoryId: [],
        assignedToAny: {
          userIds: [],
          groupIds: [],
        },
      }
    );

  const [sortByForMeetingServicesQuery, setSortByForMeetingServicesQuery] =
    useTenantLocalStorage<ListMeetingServicesSortByInput | null>(
      'meeting-service-admin-table-sort',
      null
    );

  const {
    data: meetingServicesData,
    refetch: refetchMeetingServices,
    loading,
  } = useGetMeetingServicesForAdminTableListQuery({
    variables: {
      input: {
        first: ITEMS_PER_TABLE_PAGE,
        filters: {
          ...filtersForMeetingServicesQuery,
          availableInAnyBuildingIdsOrTheirDescendants,
        },
        sortBy: sortByForMeetingServicesQuery
          ? sortByForMeetingServicesQuery
          : undefined,
      },
    },
    skip:
      loadingLocations ||
      availableInAnyBuildingIdsOrTheirDescendants.length < 1,
    notifyOnNetworkStatusChange: true,
  });

  // @TODO: Will need to pass in Kunle's data here
  const { data, refetch: refetchMeetingServicesCount } =
    useGetMeetingServicesCountForTablePaginationQuery({
      variables: {
        countMeetingInput: {
          filters: {
            ...filtersForMeetingServicesQuery,
            availableInAnyBuildingIdsOrTheirDescendants,
          },
        },
      },
      skip:
        loadingLocations ||
        availableInAnyBuildingIdsOrTheirDescendants.length < 1,
    });

  return (
    <MeetingServicesContext.Provider
      value={{
        meetingServicesData,
        refetchMeetingServices: (filters?: RefetchFilters) => {
          refetchMeetingServices({
            input: {
              first: ITEMS_PER_TABLE_PAGE,
              filters: filtersForMeetingServicesQuery,
              sortBy: sortByForMeetingServicesQuery || undefined,
              skip: filters?.skip,
              after: filters?.after,
            },
          });
        },
        meetingServicesCount: data?.countMeetingServices.count || 0,
        refetchMeetingServicesCount: () => {
          refetchMeetingServicesCount({
            countMeetingInput: {
              filters: filtersForMeetingServicesQuery,
            },
          });
        },
        loading: loading || loadingLocations,
        filtersForMeetingServicesQuery,
        setFiltersForMeetingServicesQuery,
        sortByForMeetingServicesQuery,
        setSortByForMeetingServicesQuery,
        selectedLocationIds,
        setSelectedLocationIds,
      }}
    >
      {children}
    </MeetingServicesContext.Provider>
  );
};

export const useMeetingServicesContext = (): MeetingServicesValue => {
  return useContext(MeetingServicesContext);
};
