import { useCallback, useState } from 'react';
import { Answers } from '../types/ServiceRequestTypes';

type Question = {
  id: string;
  required: boolean;
};

export const useValidateData = (questions: Question[]) => {
  const [answersHaveError, setAnswersHaveError] = useState(false);

  const isDataValid = useCallback(
    (answers: Answers) => {
      if (questions.find((q) => q.required && !answers.has(q.id))) {
        return false;
      }

      const requiredQuestions = questions.filter((q) => q.required);

      const hasInvalidQuestion = requiredQuestions?.find((question) => {
        const answerToQuestion = answers.get(question.id);
        if (
          (answerToQuestion?.type === 'choices' &&
            answerToQuestion.options.size === 0) ||
          (answerToQuestion?.type === 'text' && !answerToQuestion.text)
        ) {
          return true;
        }

        return false;
      });

      return !hasInvalidQuestion;
    },
    [questions]
  );

  return {
    isDataValid,
    setAnswersHaveError,
    answersHaveError,
  };
};
