import {
  WorkplaceServiceFormRequest,
  WorkplaceServiceRequestParentAPI,
  useManageChildPenpalMessages,
} from '@robinpowered/common-lib';
import {
  useRequestMeetingServiceForDraftEventAtSpaceForServiceRequestMutation,
  useRequestMeetingServiceForExistingEventAtSpaceForServiceRequestMutation,
} from 'generated';
import moment from 'moment';
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Answers,
  GetMeetingServiceByIdForServiceRequestQuestions,
} from '../../types/ServiceRequestTypes';
import { debounce } from 'lodash';
import { useAmplitude } from 'contexts';
import { AmplitudeEvents } from 'types';
import { mapAnswersToRequest } from 'components/ServiceRequest/utils';

type Options = {
  meetingServiceId: string | undefined;
  meetingVersionId: string | undefined;
  meetingServiceQuestions:
    | GetMeetingServiceByIdForServiceRequestQuestions
    | undefined;
  answers: Answers;
  totalPrice: string | undefined;
};

export const useConnectToParent = (
  options: Options,
  isDataValid: () => boolean
) => {
  const { t } = useTranslation('MeetingServiceRequest');
  const { trackEvent, flushEvents } = useAmplitude();
  const [initialValues, setInitialValues] =
    useState<WorkplaceServiceFormRequest | null>(null);

  const [requestMeetingServiceForDraftEventAtSpace] =
    useRequestMeetingServiceForDraftEventAtSpaceForServiceRequestMutation();

  const [requestMeetingServiceForExistingEventAtSpace] =
    useRequestMeetingServiceForExistingEventAtSpaceForServiceRequestMutation();

  const requestMeetingServiceForDraftEvent = useCallback(async () => {
    try {
      if (
        initialValues &&
        options.meetingServiceId &&
        options.meetingVersionId &&
        initialValues.event.type === 'draft'
      ) {
        // Validate info and prevent request if it fails
        if (!isDataValid()) {
          throw t(`errors.default`);
        }

        const value = await requestMeetingServiceForDraftEventAtSpace({
          variables: {
            input: {
              answers: mapAnswersToRequest(
                options.answers,
                options.meetingServiceQuestions || []
              ),
              eventEnd: {
                timeZone: initialValues.event.timezone,
                // @TODO: probably better for the parent to pass it through in ISO8601
                dateTime: moment(initialValues.event.endTime).toISOString(),
              },
              eventStart: {
                timeZone: initialValues.event.timezone,
                dateTime: moment(initialValues.event.startTime).toISOString(),
              },
              eventUid: initialValues.event.uid,
              meetingServiceId: options.meetingServiceId,
              spaceId: initialValues.spaceId,
              versionId: options.meetingVersionId,
            },
          },
        });

        const data = value.data?.requestMeetingServiceForDraftEventAtSpace;

        if (
          data?.__typename ===
          'RequestMeetingServiceForDraftEventAtSpaceErrorResponse'
        ) {
          // @TODO: map the error reasons to more frontend-y reasons, unless they already are.
          // Though we would still need to for the translations
          return {
            type: 'error',
            translatedReason: t(`errors.default`),
          };
        }

        if (
          data?.__typename ===
          'RequestMeetingServiceForDraftEventAtSpaceSuccessResponse'
        ) {
          trackEvent(AmplitudeEvents.CREATED_MEETING_SERVICE_REQUEST, {
            application: 'dashboard',
          });
          await flushEvents();
          return {
            type: 'success',
            meetingServiceRequestId: data.meetingServiceRequestId,
          };
        }
      }
    } catch (e) {
      return {
        type: 'error',
        translatedReason: t(`errors.default`),
      };
    }
    return {
      type: 'error',
      translatedReason: t(`errors.default`),
    };
  }, [
    t,
    initialValues,
    options.meetingServiceId,
    options.meetingVersionId,
    options.answers,
    options.meetingServiceQuestions,
    isDataValid,
    requestMeetingServiceForDraftEventAtSpace,
    trackEvent,
    flushEvents,
  ]);

  const requestMeetingServiceForExistingEvent = useCallback(async () => {
    try {
      if (
        initialValues &&
        options.meetingServiceId &&
        options.meetingVersionId &&
        initialValues.event.type === 'existing'
      ) {
        // Validate info and prevent request if it fails
        if (!isDataValid()) {
          throw t(`errors.default`);
        }

        const value = await requestMeetingServiceForExistingEventAtSpace({
          variables: {
            input: {
              answers: mapAnswersToRequest(
                options.answers,
                options.meetingServiceQuestions || []
              ),
              eventId: initialValues.event.id,
              meetingServiceId: options.meetingServiceId,
              spaceId: initialValues.spaceId,
              versionId: options.meetingVersionId,
            },
          },
        });

        const data = value.data?.requestMeetingServiceForExistingEventAtSpace;

        if (
          data?.__typename ===
          'RequestMeetingServiceForExistingEventAtSpaceErrorResponse'
        ) {
          // @TODO: map the error reasons to more frontend-y reasons, unless they already are.
          // Though we would still need to for the translations
          return {
            type: 'error',
            badThing: 'hiii',
            translatedReason: t(`errors.default`),
          };
        }

        if (
          data?.__typename ===
          'RequestMeetingServiceForExistingEventAtSpaceSuccessResponse'
        ) {
          trackEvent(AmplitudeEvents.CREATED_MEETING_SERVICE_REQUEST, {
            application: 'dashboard',
          });
          await flushEvents();
          return {
            type: 'success',
            meetingServiceRequestId: data.meetingServiceRequestId,
          };
        }
      }
    } catch (e) {
      return {
        type: 'error',
        translatedReason: t(`errors.default`),
      };
    }
    return {
      type: 'error',
      translatedReason: t(`errors.default`),
    };
  }, [
    t,
    initialValues,
    options.meetingServiceId,
    options.meetingVersionId,
    options.answers,
    options.meetingServiceQuestions,
    isDataValid,
    requestMeetingServiceForExistingEventAtSpace,
    trackEvent,
    flushEvents,
  ]);

  const { parent } =
    useManageChildPenpalMessages<WorkplaceServiceRequestParentAPI>({
      requestMeetingServiceForDraftEventAtSpace:
        requestMeetingServiceForDraftEvent,
      requestMeetingServiceForExistingEventAtSpace:
        requestMeetingServiceForExistingEvent,
      // Heartbeat method (see types for full description)
      pingChild: () => null,
    });

  useEffect(() => {
    const debouncedCall = debounce(() => {
      if (parent) {
        parent.onTotalPriceChange(options.totalPrice);
      }
    }, 200);

    if (parent) {
      debouncedCall();
    }

    return () => {
      debouncedCall.cancel();
    };
  }, [options.totalPrice, parent]);

  useEffect(() => {
    let shouldSetValues = true;
    const getValues = async () => {
      const values = await parent?.getInitialValues();
      if (values && shouldSetValues) {
        setInitialValues(values);
      }
    };
    getValues();
    return () => {
      shouldSetValues = false;
    };
  }, [parent]);

  return { initialValues };
};
