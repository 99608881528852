import { Body, Colors, Flex } from '@robinpowered/design-system';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import moment from 'moment';
import { Button, TableColumnsType } from '@robinpowered/ui-kit';
import { useTicketsListPageContext } from 'pages/TicketsListPage/contexts/TicketsListPageContext';
import { RobinUsers } from 'components/common/RobinUsers';
import {
  ListMeetingServiceRequestsSortByCriteria,
  MeetingServiceRequestStatus,
  SortOrder,
} from 'generated';
import { Assignee } from 'components/common/TableCells/Assignee';
import { useAuthContext } from 'contexts';
import { TicketsRoutes } from 'App';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { ServiceRequestStatus } from 'components/common/TableCells/ServiceStatus';
import Checkmark from '@robinpowered/ui-kit-icons/Checkmark';
import { Approver } from 'components/common/TableCells/Approver';
import { Close } from '@robinpowered/icons';
// import { SearchOutlined } from '@ant-design/icons';
// import type { FilterDropdownProps } from 'antd/es/table/interface';

export type TicketsListTableDataType = {
  key: string;
  status: JSX.Element;
  serviceRequestKey: JSX.Element;
  startAt: string;
  serviceName: JSX.Element;
  space: string;
  eventTitle: string;
  approver: JSX.Element;
  requester: JSX.Element;
  category: string;
  assignee: JSX.Element;
  createdAt: string;
  updatedAt: string;
  id: string;
};

export type FilterValues = {
  status: MeetingServiceRequestStatus[];
  serviceName: string[];
  requester: string[];
  approver: string[];
  assignee: string[];
  space: string[];
  category: string[];
  keyMatches: string;
};

// type DataIndex = keyof TicketsListTableDataType;

export const useManageTicketsListTable = (
  getUniqueMeetingServiceRequestValues: () => void
) => {
  const { t } = useTranslation('TicketsListPage');
  const { currentOrg } = useAuthContext();
  const {
    ticketById,
    sortByForListServiceRequestsQuery,
    handleApproveMeetingServiceRequest,
    setSelectedTicketIdForApprovalProcess,
    selectedTicketIdForApprovalProcess,
    approvalProcessing,
    filtersForMeetingServiceRequestsQuery,
    uniqueMeetingServiceRequestValues,
  } = useTicketsListPageContext();

  const navigate = useNavigate();

  const tableActions = useCallback(
    (id: string) => {
      const meetingServiceRequest = ticketById.get(id);

      const userCanApprove =
        !!meetingServiceRequest?.permissions?.canApproveOrReject &&
        meetingServiceRequest?.meetingServiceRequestStatus ===
          MeetingServiceRequestStatus.NeedsApproval;

      // TODO: use for actions below
      // const userCanChangeAnswers =
      //   !!meetingServiceRequest?.permissions?.canChangeAnswers;
      // const userCanDeleteOrCancel =
      //   !!meetingServiceRequest?.permissions?.canDeleteOrCancel;

      const isProcessingApproval =
        approvalProcessing &&
        selectedTicketIdForApprovalProcess?.id === meetingServiceRequest?.id;

      return (
        <Flex style={{ gap: 16, display: 'flex', justifyContent: 'center' }}>
          {userCanApprove ? (
            <>
              <Button
                size="small"
                type="primary"
                icon={<Checkmark />}
                loading={isProcessingApproval}
                onClick={() => {
                  setSelectedTicketIdForApprovalProcess({
                    id: meetingServiceRequest.id,
                    status: 'approving',
                  });
                  handleApproveMeetingServiceRequest({
                    latestChangeId: meetingServiceRequest.latestChangeId,
                    meetingServiceRequestId: meetingServiceRequest.id,
                  });
                }}
              />
              <Button
                size="small"
                type="default"
                style={{
                  borderColor: Colors.Red100,
                }}
                loading={isProcessingApproval}
                icon={<Close color={Colors.Red100} size={14} />}
                onClick={() =>
                  setSelectedTicketIdForApprovalProcess({
                    id: meetingServiceRequest.id,
                    status: 'rejecting',
                  })
                }
              />
            </>
          ) : (
            <>
              {/* TODO: check permissions before showing buttons */}
              {/* <Button icon={<EditAlt size={16} />} disabled></Button>
              <Button icon={<TrashSolid size={16} />} disabled></Button> */}
            </>
          )}
        </Flex>
      );
    },
    [
      ticketById,
      handleApproveMeetingServiceRequest,
      setSelectedTicketIdForApprovalProcess,
      approvalProcessing,
      selectedTicketIdForApprovalProcess,
    ]
  );

  /* @TODO Add key search UI */
  // const searchInput = useRef<InputRef>(null);
  // const handleSearch = (
  //   selectedKeys: string[],
  //   confirm: FilterDropdownProps['confirm'],
  //   dataIndex: DataIndex
  // ) => {
  //   handleSetFiltersForMeetingServiceRequestsQuery({
  //     keyMatches: selectedKeys,
  //   });
  //   // confirm();
  //   // setSearchText(selectedKeys[0]);
  //   // setSearchedColumn(dataIndex);
  // };

  // const handleReset = (clearFilters: () => void) => {
  //   clearFilters();
  //   // setSearchText('');
  // };

  // const getColumnSearchProps = (
  //   dataIndex: DataIndex
  // ): TableColumnType<TicketsListTableDataType> => ({
  //   filterDropdown: ({
  //     setSelectedKeys,
  //     selectedKeys,
  //     confirm,
  //     clearFilters,
  //     close,
  //   }) => (
  //     <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
  //       <Input
  //         ref={searchInput}
  //         placeholder={`Search ${dataIndex}`}
  //         value={selectedKeys[0]}
  //         onChange={(e) =>
  //           setSelectedKeys(e.target.value ? [e.target.value] : [])
  //         }
  //         onPressEnter={() =>
  //           handleSearch(selectedKeys as string[], confirm, dataIndex)
  //         }
  //         style={{ marginBottom: 8, display: 'block' }}
  //       />
  //       <Space>
  //         <Button
  //           type="primary"
  //           onClick={() =>
  //             handleSearch(selectedKeys as string[], confirm, dataIndex)
  //           }
  //           icon={<SearchOutlined />}
  //           size="small"
  //           style={{ width: 90 }}
  //         >
  //           Search
  //         </Button>
  //         <Button
  //           onClick={() => clearFilters && handleReset(clearFilters)}
  //           size="small"
  //           style={{ width: 90 }}
  //         >
  //           Reset
  //         </Button>
  //         <Button
  //           type="link"
  //           size="small"
  //           onClick={() => {
  //             close();
  //           }}
  //         >
  //           close
  //         </Button>
  //       </Space>
  //     </div>
  //   ),
  //   filterIcon: (filtered: boolean) => (
  //     <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
  //   ),
  //   // onFilter: (value, record) => {
  //   //   return record[dataIndex]
  //   //     .toString()
  //   //     .toLowerCase()
  //   //     .includes((value as string).toLowerCase());
  //   // },
  //   render: (text) => text,
  // });

  // From AntDesign table docs: please try to leave one column at least without width to fit fluid layout
  const columns: TableColumnsType<TicketsListTableDataType> = [
    {
      title: t('table.columns.status'),
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      sortOrder:
        sortByForListServiceRequestsQuery?.criteria ===
        ListMeetingServiceRequestsSortByCriteria.Status
          ? sortByForListServiceRequestsQuery?.order === SortOrder.Ascending
            ? 'ascend'
            : 'descend'
          : undefined,
      filters: [...Object.values(MeetingServiceRequestStatus)].map(
        (status) => ({
          text: t(`tickets.status.${status.toLowerCase()}`),
          value: status,
        })
      ),
      filterOnClose: false,
      filteredValue: filtersForMeetingServiceRequestsQuery?.hasAnyStatus,
      defaultFilteredValue: filtersForMeetingServiceRequestsQuery?.hasAnyStatus,
    },
    {
      title: t('table.columns.service_key'),
      dataIndex: 'serviceRequestKey',
      key: 'serviceRequestKey',
      // ...getColumnSearchProps('serviceName'),
      sorter: true,
      sortOrder:
        sortByForListServiceRequestsQuery?.criteria ===
        ListMeetingServiceRequestsSortByCriteria.Key
          ? sortByForListServiceRequestsQuery?.order === SortOrder.Ascending
            ? 'ascend'
            : 'descend'
          : undefined,
    },
    {
      title: t('table.columns.start_at'),
      dataIndex: 'startAt',
      key: 'startAt',
      width: 175,
      sorter: true,
      filterSearch: true,
      sortOrder:
        sortByForListServiceRequestsQuery?.criteria ===
        ListMeetingServiceRequestsSortByCriteria.EventStartTime
          ? sortByForListServiceRequestsQuery?.order === SortOrder.Ascending
            ? 'ascend'
            : 'descend'
          : undefined,
    },
    {
      title: t('table.columns.service_name'),
      dataIndex: 'serviceName',
      key: 'serviceName',
      width: 175,
      sorter: true,
      sortOrder:
        sortByForListServiceRequestsQuery?.criteria ===
        ListMeetingServiceRequestsSortByCriteria.ServiceName
          ? sortByForListServiceRequestsQuery?.order === SortOrder.Ascending
            ? 'ascend'
            : 'descend'
          : undefined,
      filterSearch: true,
      onFilterDropdownOpenChange: () => {
        getUniqueMeetingServiceRequestValues();
      },
      filters:
        uniqueMeetingServiceRequestValues?.meetingServices.map((service) => ({
          text: service.name,
          value: service.id,
        })) || [],
      filterOnClose: false,
      filteredValue:
        filtersForMeetingServiceRequestsQuery?.serviceHasAnyId || [],
      defaultFilteredValue:
        filtersForMeetingServiceRequestsQuery?.serviceHasAnyId,
    },
    {
      title: t('table.columns.space'),
      dataIndex: 'space',
      key: 'space',
      filterSearch: true,
      onFilterDropdownOpenChange: () => {
        getUniqueMeetingServiceRequestValues();
      },
      filters:
        uniqueMeetingServiceRequestValues?.regardingSpaces.map(
          (space) =>
            ({
              text: space?.name || '',
              value: space?.id || '',
            } || [])
        ) || [],
      filterOnClose: false,
      filteredValue:
        filtersForMeetingServiceRequestsQuery?.regardingAnySpaceIds,
      defaultFilteredValue:
        filtersForMeetingServiceRequestsQuery?.regardingAnySpaceIds,
    },
    {
      title: t('table.columns.event_title'),
      dataIndex: 'eventTitle',
      minWidth: 200,
      key: 'eventTitle',
    },
    {
      title: t('table.columns.approver'),
      dataIndex: 'approver',
      key: 'approver',
      width: 175,
      filterSearch: true,
      onFilterDropdownOpenChange: () => {
        getUniqueMeetingServiceRequestValues();
      },
      filters:
        uniqueMeetingServiceRequestValues?.approvers.map((approver) => {
          return {
            text:
              approver?.__typename === 'Group'
                ? approver?.groupName || ''
                : approver?.__typename === 'User'
                ? approver?.userName || ''
                : '',
            value: approver?.id || '',
            type: approver?.__typename,
          };
        }) || [],
      filterOnClose: false,
      filteredValue: [
        ...(filtersForMeetingServiceRequestsQuery?.hasAnyApprover?.groupIds.map(
          (id) => id
        ) || []),
        ...(filtersForMeetingServiceRequestsQuery?.hasAnyApprover?.userIds.map(
          (id) => id
        ) || []),
      ],
      defaultFilteredValue: [
        ...(filtersForMeetingServiceRequestsQuery?.hasAnyApprover?.groupIds.map(
          (id) => id
        ) || []),
        ...(filtersForMeetingServiceRequestsQuery?.hasAnyApprover?.userIds.map(
          (id) => id
        ) || []),
      ],
    },
    {
      title: t('table.columns.requester'),
      dataIndex: 'requester',
      key: 'requester',
      filterSearch: true,
      onFilterDropdownOpenChange: () => {
        getUniqueMeetingServiceRequestValues();
      },
      filters:
        uniqueMeetingServiceRequestValues?.requesters.map(
          (requester) =>
            ({
              text: requester?.name || '',
              value: requester?.id || '',
            } || [])
        ) || [],
      filterOnClose: false,
      filteredValue:
        filtersForMeetingServiceRequestsQuery?.hasAnyRequesterUserId,
      defaultFilteredValue:
        filtersForMeetingServiceRequestsQuery?.hasAnyRequesterUserId,
    },
    {
      title: t('table.columns.assignee'),
      dataIndex: 'assignee',
      key: 'assignee',
      filterSearch: true,
      onFilterDropdownOpenChange: () => {
        getUniqueMeetingServiceRequestValues();
      },
      filters:
        uniqueMeetingServiceRequestValues?.assignees.map((assignee) => {
          return {
            text:
              assignee?.__typename === 'Group'
                ? assignee?.groupName || ''
                : assignee?.__typename === 'User'
                ? assignee?.userName || ''
                : '',
            value: assignee?.id || '',
            type: assignee?.__typename,
          };
        }) || [],
      filterOnClose: false,
      filteredValue: [
        ...(filtersForMeetingServiceRequestsQuery?.hasAnyAssignee?.groupIds.map(
          (id) => id
        ) || []),
        ...(filtersForMeetingServiceRequestsQuery?.hasAnyAssignee?.userIds.map(
          (id) => id
        ) || []),
      ],
      defaultFilteredValue: [
        ...(filtersForMeetingServiceRequestsQuery?.hasAnyAssignee?.groupIds.map(
          (id) => id
        ) || []),
        ...(filtersForMeetingServiceRequestsQuery?.hasAnyAssignee?.userIds.map(
          (id) => id
        ) || []),
      ],
    },
    {
      title: t('table.columns.category'),
      dataIndex: 'category',
      key: 'category',
      width: 175,
      sorter: true,
      sortOrder:
        sortByForListServiceRequestsQuery?.criteria ===
        ListMeetingServiceRequestsSortByCriteria.ServiceCategoryName
          ? sortByForListServiceRequestsQuery?.order === SortOrder.Ascending
            ? 'ascend'
            : 'descend'
          : undefined,
      filterSearch: true,
      onFilterDropdownOpenChange: () => {
        getUniqueMeetingServiceRequestValues();
      },
      filters:
        uniqueMeetingServiceRequestValues?.meetingServicesCategories.map(
          (category) => ({
            text: category?.name || '',
            value: category?.id || '',
          })
        ) || [],
      filterOnClose: false,
      filteredValue:
        filtersForMeetingServiceRequestsQuery?.serviceBelongsToAnyCategoryIds,
      defaultFilteredValue:
        filtersForMeetingServiceRequestsQuery?.serviceBelongsToAnyCategoryIds,
    },
    {
      title: t('table.columns.created_at'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      sortOrder:
        sortByForListServiceRequestsQuery?.criteria ===
        ListMeetingServiceRequestsSortByCriteria.CreatedAt
          ? sortByForListServiceRequestsQuery?.order === SortOrder.Ascending
            ? 'ascend'
            : 'descend'
          : undefined,
    },
    {
      title: t('table.columns.updated_at'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: true,
      sortOrder:
        sortByForListServiceRequestsQuery?.criteria ===
        ListMeetingServiceRequestsSortByCriteria.UpdatedAt
          ? sortByForListServiceRequestsQuery?.order === SortOrder.Ascending
            ? 'ascend'
            : 'descend'
          : undefined,
      width: 175,
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: (_text, record) => {
        return tableActions(record.id);
      },
    },
  ];

  const tableData = useMemo(() => {
    return (
      Array.from(ticketById.values())?.map((serviceRequest) => {
        return {
          key: serviceRequest.key,
          status: (
            <ServiceRequestStatus
              status={serviceRequest.meetingServiceRequestStatus}
              meetingServiceRequestId={serviceRequest.id}
              userCanUpdateStatus={
                serviceRequest.permissions?.canUpdateStatus ?? false
              }
            />
          ),
          serviceRequestKey: (
            <Button
              type="link"
              aria-label="Open the ticket details in sidebar"
              onClick={() => {
                navigate({
                  pathname: `/${currentOrg?.slug}/tickets/${TicketsRoutes.TICKETS_LIST}`,
                  search: createSearchParams({
                    serviceRequestKey: serviceRequest.key,
                  }).toString(),
                });
              }}
            >
              <TruncatedName title={serviceRequest.key}>
                {serviceRequest.key}
              </TruncatedName>
            </Button>
          ),
          startAt: moment(serviceRequest.regardsEventAtSpace.eventStart).format(
            'MMM D h:mm a'
          ),
          serviceName: (
            <TruncatedName title={serviceRequest.meetingService.name}>
              {serviceRequest.meetingService.name}
            </TruncatedName>
          ),
          space: serviceRequest.regardsEventAtSpace.space?.name || '',
          eventTitle:
            serviceRequest.regardsEventAtSpace.event?.title ||
            t('table.rows.event.no_title') ||
            '',
          approver: <Approver approver={serviceRequest.approvers} />,
          requester: <RobinUsers users={[serviceRequest.requester]} />,
          category: serviceRequest.meetingService.category.name,
          assignee: <Assignee assignees={serviceRequest.assignees} />,
          createdAt: moment(serviceRequest.createdAt).format('MMM D h:mm a'),
          updatedAt: moment(serviceRequest.updatedAt).format('MMM D h:mm a'),
          id: serviceRequest.id /* @TODO Hidden column. Kind of a hack but can't see any issues rn */,
        };
      }) || []
    );
  }, [ticketById, t, navigate, currentOrg?.slug]);

  return {
    tableData,
    columns,
  };
};

const TruncatedName = styled(Body.Small)`
  max-width: 350px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;
