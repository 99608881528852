import {
  createContext,
  useContext,
  FC,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useMemo,
  ReactNode,
} from 'react';
import { useGetServiceRequestDetails } from '../hooks/useGetServiceRequestDetails';
import { SelectOption } from 'components/ServiceForm/components/Select';
import { useSelectedCategory } from '../hooks/useSelectedCategory';
import {
  MeetingService,
  MeetingServiceEvent,
} from '../../types/ServiceRequestTypes';
import { useConnectToParent } from '../hooks/useConnectToParent';
import { useValidateData } from '../../hooks/useValidateData';
import {
  Answers,
  GetMeetingServiceByIdForServiceRequestAssignees,
  GetMeetingServiceByIdForServiceRequestQuestions,
  GetMeetingServiceByIdForServiceRequestApprovers,
  selectedServiceWithQuestions,
} from '../../types/ServiceRequestTypes';
import { getTotalPrice } from '../../utils';
import { WorkplaceServiceFormRequest } from '@robinpowered/common-lib';

type RequestingServiceContextValue = {
  requestedBy:
    | {
        name?: string | null | undefined;
        avatar?: string | null | undefined;
      }
    | undefined;
  assignedTo: string[];
  loading: boolean;
  selectedServiceWithoutQuestions: MeetingService | undefined;
  selectedCategory: { value: string; label: string; id: string };
  selectCategory: (category: {
    value: string;
    label: string;
    id: string;
  }) => void;
  selectService: (serviceId: string) => void;
  loadingLocation: boolean;
  categories: SelectOption[];
  services: MeetingService[];
  assignees: GetMeetingServiceByIdForServiceRequestAssignees;
  approver: GetMeetingServiceByIdForServiceRequestApprovers;
  serviceDescription: string | null | undefined;
  serviceRequestAnswers: Answers;
  setServiceRequestAnswers: Dispatch<SetStateAction<Answers>>;
  answersHaveError: boolean;
  setAnswersHaveError: Dispatch<SetStateAction<boolean>>;
  isRecurring: boolean | null | undefined;
  gettingServiceWithQuestions: boolean;
  questions: GetMeetingServiceByIdForServiceRequestQuestions | undefined;
} & MeetingServiceEvent;

const RequestingServiceContext = createContext<RequestingServiceContextValue>({
  requestedBy: undefined,
  assignedTo: [],
  locationName: '',
  dueDate: '',
  numOfAttendees: 0,
  loading: true,
  selectedServiceWithoutQuestions: undefined,
  selectedCategory: {
    value: '',
    label: '',
    id: '',
  },
  selectCategory: () => {},
  selectService: () => {},
  loadingLocation: false,
  categories: [],
  services: [],
  assignees: undefined,
  approver: undefined,
  serviceDescription: '',
  serviceRequestAnswers: new Map(),
  setServiceRequestAnswers: () => null,
  answersHaveError: false,
  setAnswersHaveError: () => null,
  isRecurring: false,
  gettingServiceWithQuestions: false,
  questions: [],
});

export const RequestingServiceContextProvider: FC<{
  children: ReactNode;
  __testInitialValues__?: WorkplaceServiceFormRequest;
}> = ({ children, __testInitialValues__ }) => {
  const [meetingService, setMeetingService] =
    useState<selectedServiceWithQuestions>(undefined);

  const [serviceRequestAnswers, setServiceRequestAnswers] = useState<Answers>(
    () => new Map()
  );

  const { isDataValid, answersHaveError, setAnswersHaveError } =
    useValidateData(meetingService?.questions || []);

  const totalPrice = useMemo(() => {
    return getTotalPrice(
      serviceRequestAnswers,
      meetingService?.questions || [],
      meetingService?.currencyCode
    );
  }, [meetingService, serviceRequestAnswers]);

  const { initialValues: parentInitialValues } = useConnectToParent(
    {
      meetingServiceId: meetingService?.id,
      meetingVersionId: meetingService?.versionId,
      meetingServiceQuestions: meetingService?.questions,
      answers: serviceRequestAnswers,
      totalPrice,
    },
    () => {
      const validData = isDataValid(serviceRequestAnswers);
      if (!validData) {
        setAnswersHaveError(true);
      }

      return validData;
    }
  );

  const initialValues = useMemo(
    () => __testInitialValues__ || parentInitialValues,
    [parentInitialValues, __testInitialValues__]
  );

  const {
    dueDate,
    locationName,
    numOfAttendees,
    requestedBy,
    loading,
    loadingLocation,
  } = useGetServiceRequestDetails(initialValues);
  const {
    selectService,
    selectCategory,
    selectedServiceWithoutQuestions,
    selectedCategory,
    categories,
    services,
    serviceWithQuestions,
    gettingServiceWithQuestions,
  } = useSelectedCategory(initialValues?.spaceId);

  useEffect(() => {
    if (serviceWithQuestions) {
      setMeetingService(
        serviceWithQuestions.getMeetingServiceById.meetingService
      );
    }
  }, [serviceWithQuestions]);

  return (
    <RequestingServiceContext.Provider
      value={{
        requestedBy,
        //@TODO: implement this with the category select dropdown
        assignedTo: [],
        assignees: meetingService?.assignees,
        approver: meetingService?.approvers,
        serviceDescription: meetingService?.description,
        dueDate,
        locationName,
        numOfAttendees,
        loading,
        selectService: (serviceId) => {
          // Clear the question answers and errors when choosing a new service or category
          setServiceRequestAnswers(new Map());
          setAnswersHaveError(false);
          selectService(serviceId);
        },
        selectCategory: (category) => {
          // Clear the question answers and errors when choosing a new service or category
          setServiceRequestAnswers(new Map());
          setAnswersHaveError(false);
          selectCategory(category);
        },
        selectedServiceWithoutQuestions,
        selectedCategory,
        loadingLocation,
        categories,
        services,
        serviceRequestAnswers,
        setServiceRequestAnswers,
        questions: meetingService?.questions,
        answersHaveError,
        setAnswersHaveError,
        isRecurring: initialValues?.isRecurring,
        gettingServiceWithQuestions,
      }}
    >
      {children}
    </RequestingServiceContext.Provider>
  );
};

export const useRequestingServiceContext = () => {
  return useContext(RequestingServiceContext);
};
