import React from 'react';
import { GroupCell } from './Groups';
import { GetTicketsForTicketsListPageQuery } from 'generated';
import { RobinUsers } from '../RobinUsers';

type AssigneesQueryType =
  GetTicketsForTicketsListPageQuery['listMeetingServiceRequests']['meetingServiceRequests'][0]['assignees'];

type Props = {
  assignees: AssigneesQueryType | undefined;
};

export const Assignee = ({ assignees }: Props) => {
  const users = assignees?.usersOutsideOfGroups;
  const group = assignees?.groups[0];

  if (group) {
    return (
      <GroupCell
        group={group}
        totalUsers={group.members.length + (users ? users.length : 0)}
      />
    );
  }

  if (users && users[0]) {
    return <RobinUsers users={users} />;
  }

  return null;
};
