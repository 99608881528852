import styled from '@emotion/styled';
import { Avatar } from '@robinpowered/ui-kit';
import { RobinAvatar } from './RobinAvatar';
import { Body } from '@robinpowered/design-system';

type Props = {
  users:
    | (
        | {
            __typename?: 'User';
            id: string;
            name?: string | null;
            avatar?: string | null;
          }
        | null
        | undefined
      )[]
    | undefined;
};

// Used to convert a list of users into the appropriate 'user' view
// i.e. avatar group, avatar with text, etc
export const RobinUsers = ({ users }: Props) => {
  if (!users || !users[0]) {
    return null;
  }

  if (users.length === 1) {
    const user = users[0];

    return (
      <UserContainer>
        <RobinAvatar avatar={user.avatar} name={user.name} />
        <Body.Small>{user.name}</Body.Small>
      </UserContainer>
    );
  }

  const avatarList = users.slice(0, 3).map((u) => {
    return <RobinAvatar key={u?.id} avatar={u?.avatar} name={u?.name} />;
  });

  return (
    <UserContainer>
      <Avatar.Group size={'small'} max={{ count: 3 }}>
        {avatarList}
      </Avatar.Group>
    </UserContainer>
  );
};

const UserContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
