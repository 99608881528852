import {
  createContext,
  useContext,
  FC,
  ReactNode,
  useCallback,
  useState,
  useMemo,
  Dispatch,
  SetStateAction,
  useEffect,
  ReactElement,
  JSXElementConstructor,
} from 'react';
import { useManageMeetingServicesTable } from '../hooks';
import {
  ITEMS_PER_TABLE_PAGE,
  useMeetingServicesContext,
} from 'pages/MeetingServicePage/contexts/ServicesContext';
import {
  GetMeetingServicesForAdminTableListQuery,
  useDeleteMeetingServicesMutation,
  useGetMeetingServiceCategoriesForFormQuery,
  useListUniqueAssigneesForMeetingServiceAdminFiltersQuery,
} from 'generated';
import { useTranslation } from 'react-i18next';
import { TableColumnsType } from '@robinpowered/ui-kit';
import { TableDataType } from '../hooks/useManageMeetingServicesTable';
import { message } from 'antd';
import { NoticeType } from 'antd/es/message/interface';
import { useAuthContext } from 'contexts';

export type MeetingServiceType =
  GetMeetingServicesForAdminTableListQuery['listMeetingServices']['meetingServices'][0];

type MeetingServicesTableValue = {
  tableData: TableDataType[];
  columns: TableColumnsType;
  handlePageNumberClick: (pageNum: number) => void;
  activePage: number;
  selectedMeetingServices: Set<string>;
  showConfirmDelete: boolean;
  setShowConfirmDelete: Dispatch<SetStateAction<boolean>>;
  meetingServicesById: MeetingServiceByIdType;
  onDeleteMeetingServices: () => void;
  deletingServices: boolean;
  loadingMeetingServiceTable: boolean;
  setMeetingServicesById: Dispatch<SetStateAction<MeetingServiceByIdType>>;
  rowSelection: {
    onChange: (selectedRowKeys: React.Key[]) => void;
  };
  toastContextHolder: ReactElement<
    unknown,
    string | JSXElementConstructor<unknown>
  >;
  buildAssigneeFiltersForQuery: (ids: string[] | null) =>
    | {
        userIds: string[];
        groupIds: string[];
      }
    | undefined;
  setSkipItems: Dispatch<SetStateAction<number>>;
  deselectAllMeetingServices: () => void;
};

const MeetingServicesTableContext = createContext<MeetingServicesTableValue>({
  tableData: [],
  columns: [],
  handlePageNumberClick: () => null,
  activePage: 0,
  selectedMeetingServices: new Set(),
  showConfirmDelete: false,
  setShowConfirmDelete: () => null,
  meetingServicesById: new Map(),
  onDeleteMeetingServices: () => null,
  deletingServices: false,
  loadingMeetingServiceTable: false,
  setMeetingServicesById: () => null,
  rowSelection: {
    onChange: () => null,
  },
  toastContextHolder: <></>,
  buildAssigneeFiltersForQuery: () => {
    return { userIds: [], groupIds: [] };
  },
  setSkipItems: () => null,
  deselectAllMeetingServices: () => null,
});

export type MeetingServiceByIdType = Map<
  string,
  {
    selected: boolean;
    meetingService: MeetingServiceType;
  }
>;

type Props = {
  children: ReactNode;
};

export const MeetingServicesTableContextProvider: FC<Props> = ({
  children,
}) => {
  const { t } = useTranslation('MeetingServicePage');
  const [skipItems, setSkipItems] = useState(0);
  const { loading: authLoading } = useAuthContext();

  const {
    meetingServicesData,
    refetchMeetingServices,
    loading: loadingMeetingServices,
    selectedLocationIds,
  } = useMeetingServicesContext();

  const [messageApi, contextHolder] = message.useMessage();

  const toastMessage = (type: NoticeType, message: string) => {
    messageApi.open({
      type,
      content: message,
    });
  };

  const serviceAvailableInAnyBuildingIdsOrTheirDescendants = useMemo(
    () => selectedLocationIds.map((l) => l),
    [selectedLocationIds]
  );

  //@TODO: implement pagination with this to not list or limit all categories loaded
  const { data: meetingServicesCategories } =
    useGetMeetingServiceCategoriesForFormQuery({
      variables: {
        input: {
          first: 100,
          filters: {
            hasServicesAvailableInBuildingIds:
              serviceAvailableInAnyBuildingIdsOrTheirDescendants,
          },
        },
      },
      skip: authLoading,
    });

  //@TODO: implement pagination with this to not list or limit all assignees loaded
  const { data: uniqueAssigneesForMeetingServices } =
    useListUniqueAssigneesForMeetingServiceAdminFiltersQuery({
      variables: {
        input: {
          first: 100,
          filters: {
            serviceAvailableInAnyBuildingIdsOrTheirDescendants,
          },
        },
      },
      skip: authLoading,
    });

  /* Mapping the meeting services to their respective IDs */
  const [meetingServicesById, setMeetingServicesById] = useState(
    new Map(
      (meetingServicesData?.listMeetingServices.meetingServices || []).map(
        (service) => [service.id, { selected: false, meetingService: service }]
      )
    )
  );

  useEffect(() => {
    setMeetingServicesById(
      new Map(
        (meetingServicesData?.listMeetingServices.meetingServices || []).map(
          (service) => [
            service.id,
            { selected: false, meetingService: service },
          ]
        )
      )
    );
  }, [meetingServicesData?.listMeetingServices.meetingServices]);

  const selectedMeetingServices = useMemo(() => {
    return new Set(
      [...meetingServicesById.entries()]
        .filter(([, { selected }]) => selected)
        .map(([id]) => id)
    );
  }, [meetingServicesById]);

  const rowSelection = {
    selectedRowKeys: [...selectedMeetingServices.keys()],
    onChange: (selectedRowKeys: React.Key[]) => {
      setMeetingServicesById((prev) => {
        const current = new Map(prev);
        return new Map(
          [...current.entries()].map(([id, service]) => [
            id,
            {
              ...service,
              selected: selectedRowKeys.includes(id),
            },
          ])
        );
      });
    },
  };

  const categoryFilters = useMemo(() => {
    return (
      meetingServicesCategories?.listMeetingServicesCategories.meetingServicesCategories.map(
        (c) => {
          return { text: c.name, value: c.id };
        }
      ) || []
    );
  }, [
    meetingServicesCategories?.listMeetingServicesCategories
      .meetingServicesCategories,
  ]);

  const assigneeFilters = useMemo(() => {
    return (
      uniqueAssigneesForMeetingServices?.listUniqueAssigneesForMeetingServices.uniqueAssignees.map(
        (assignee) => {
          if (assignee?.__typename === 'Group') {
            return {
              text: assignee.groupName,
              value: assignee.id,
              type: 'Group' as const,
            };
          }
          return {
            text: assignee?.userName || assignee?.primaryEmail?.email || '',
            value: assignee?.id || '',
            type: 'User' as const,
          };
        }
      ) || []
    );
  }, [
    uniqueAssigneesForMeetingServices?.listUniqueAssigneesForMeetingServices
      .uniqueAssignees,
  ]);

  const buildAssigneeFiltersForQuery = useCallback(
    (ids: string[] | null) => {
      if (!ids || ids.length === 0) {
        return undefined;
      }
      const groupIds: string[] = [];
      const userIds: string[] = [];

      ids.forEach((id) => {
        const assignee = assigneeFilters.find((a) => a.value === id);

        if (assignee?.type === 'Group') {
          groupIds.push(assignee.value);
        }

        if (assignee?.type === 'User') {
          userIds.push(assignee.value);
        }
        return;
      });

      return { userIds, groupIds };
    },
    [assigneeFilters]
  );

  const { tableData, columns, showConfirmDelete, setShowConfirmDelete } =
    useManageMeetingServicesTable(meetingServicesById, setMeetingServicesById, {
      assignee: assigneeFilters,
      category: categoryFilters,
    });

  const [deleteMeetingServices, { loading: deletingServices }] =
    useDeleteMeetingServicesMutation({
      variables: { meetingServiceIds: [...selectedMeetingServices] },
    });

  const onDeleteMeetingServices = () => {
    const totalServicesBeingDeleted = selectedMeetingServices.size;
    deleteMeetingServices({
      variables: { meetingServiceIds: [...selectedMeetingServices] },
    })
      .then(() => {
        setShowConfirmDelete(false);
        refetchMeetingServices();

        if (totalServicesBeingDeleted === 1) {
          toastMessage('success', t(`confirm_delete.single.deleted`));
        } else {
          toastMessage(
            'success',
            t(`confirm_delete.multiple.deleted`, {
              totalServices: totalServicesBeingDeleted,
            })
          );
        }
      })
      .catch(() => {
        setShowConfirmDelete(false);
        toastMessage('error', t(`confirm_delete.something_went_wrong`));
      });
  };

  const deselectAllMeetingServices = () =>
    setMeetingServicesById((prev) => {
      const current = new Map(prev);
      return new Map(
        [...current.entries()].map(([id, service]) => [
          id,
          {
            ...service,
            selected: false,
          },
        ])
      );
    });

  const activePage = useMemo(
    () => (skipItems ? skipItems / ITEMS_PER_TABLE_PAGE + 1 : 1),
    [skipItems]
  );

  const handlePageNumberClick = useCallback(
    (pageNum: number) => {
      if (pageNum === activePage) {
        return;
      }

      const itemsSkipped = (pageNum - 1) * ITEMS_PER_TABLE_PAGE;

      refetchMeetingServices({
        skip: itemsSkipped ? itemsSkipped : undefined,
      });

      setSkipItems(itemsSkipped);
    },
    [activePage, refetchMeetingServices]
  );

  return (
    <MeetingServicesTableContext.Provider
      value={{
        tableData,
        columns,
        activePage,
        handlePageNumberClick,
        selectedMeetingServices,
        showConfirmDelete,
        setShowConfirmDelete,
        meetingServicesById,
        onDeleteMeetingServices,
        deletingServices,
        loadingMeetingServiceTable: loadingMeetingServices,
        setMeetingServicesById,
        rowSelection,
        toastContextHolder: contextHolder,
        buildAssigneeFiltersForQuery,
        setSkipItems,
        deselectAllMeetingServices,
      }}
    >
      {children}
    </MeetingServicesTableContext.Provider>
  );
};

export const useMeetingServicesTableContext = (): MeetingServicesTableValue => {
  return useContext(MeetingServicesTableContext);
};
