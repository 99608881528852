import { useCallback, useMemo } from 'react';
import moment from 'moment-timezone';
import type { Moment } from 'moment-timezone';
import { TicketsDatePickerProps } from '../DatePicker';

export const useDatePicker = ({
  dateRange,
  setDateRange,
  resetDateRange,
}: TicketsDatePickerProps) => {
  const today = moment().tz(moment.tz.guess()).startOf('day');

  const onRangeChange = useCallback(
    (dates: null | (Moment | null)[]) => {
      if (!dates) {
        resetDateRange();
        return;
      }

      const [startDate, endDate] = dates;
      const startDateRange = startDate?.startOf('day') || today;
      const endDateRange =
        endDate?.endOf('day') || startDateRange.add(1, 'day');

      setDateRange({
        startDate: startDateRange.toISOString(),
        endDate: endDateRange.toISOString(),
      });
    },
    [setDateRange, resetDateRange, today]
  );

  const dateValues: [Moment, Moment] = useMemo(() => {
    return [moment(dateRange.startDate), moment(dateRange.endDate)];
  }, [dateRange]);

  // This date is hardcoded into the backend, queries before it will not resolve
  const EARLIEST_SELECTABLE_DATE = moment('2024-06-01')
    .tz(moment.tz.guess())
    .startOf('day');

  return {
    onRangeChange,
    dateValues,
    minimumDate: EARLIEST_SELECTABLE_DATE,
  };
};
