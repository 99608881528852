import styled from '@emotion/styled';
import { SelectLocations } from '../../common/SelectedLocations/SelectLocations';
import { useManageMeetingServicesLocationContext } from 'contexts/LocationsContext';
import { useMeetingServicesContext } from 'pages/MeetingServicePage/contexts/ServicesContext';

export const MeetingServicesFilters = () => {
  const { selectedLocationIds, setSelectedLocationIds } =
    useMeetingServicesContext();
  const { loading } = useManageMeetingServicesLocationContext();

  return (
    <MeetingServiceFilterWrapper>
      <SelectLocations
        onSelectLocation={setSelectedLocationIds}
        selectedLocations={selectedLocationIds}
        loading={loading}
      />
    </MeetingServiceFilterWrapper>
  );
};

const MeetingServiceFilterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
