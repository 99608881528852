import { GetTicketsForCsvExportQuery } from 'generated/graphql';

export const getInitials = function (string: string) {
  if (!string) {
    return '';
  }

  const names = string.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

type TotalPrice = {
  amountMajor: string;
  currencyCode: string;
};

export const formatCurrency = (totalPrice: TotalPrice) => {
  return new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency: totalPrice?.currencyCode,
    // can take a string, types are off: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/format
  }).format(totalPrice?.amountMajor as unknown as number);
};

export const mapTicketToCSVData = (
  ticket: GetTicketsForCsvExportQuery['listMeetingServiceRequests']['meetingServiceRequests'][number]
) => {
  // Grab the questions and answers for each ticket and make the prompts the keys so the prompts
  // can be used as the headers for the CSV file.  Then format the data into
  const answersData = ticket.completedForm?.questions?.map((question) => {
    const questionAnswerMap = question.question.prompt
      ? {
          [question.question.prompt]:
            question.__typename === 'AnsweredTicketQuestionExpectingText'
              ? question.answer
              : question.__typename === 'AnsweredTicketQuestionExpectingChoices'
              ? question.choices?.map((choice) => choice.name).join(', ')
              : question.__typename ===
                'AnsweredTicketQuestionExpectingMenuChoices'
              ? question.choices
                  ?.map(
                    (choice) =>
                      `${choice.quantity ? `${choice.quantity}x ` : ''}${
                        choice.option.name
                      } ${
                        choice.subtotalPrice?.currencyCode
                          ? formatCurrency(choice.subtotalPrice)
                          : ''
                      }`
                  )
                  .join(', ')
              : '',
        }
      : {};

    return questionAnswerMap;
  });
  const answersFormatted =
    answersData?.reduce((acc, curr) => ({ ...acc, ...curr }), {}) || {};

  // Big old object which maps the large response to all of the keys which will end up being
  // the headers for the CSV file we generate
  return {
    Key: ticket.key || '',
    Status: ticket.meetingServiceRequestStatus || '',
    'Requester User Id': ticket.requester?.id || '',
    'Requester Name': ticket.requester?.name || '',
    'Assignee User Ids Inclusive Of Group Members': [
      ...(ticket.assignees?.groups?.map(
        (group) => group?.members.map((member) => member.id) || []
      ) || []),
      ...(ticket.assignees?.usersOutsideOfGroups?.map(
        (user) => user?.id || ''
      ) || []),
    ]
      .filter((id, index, self) => self.indexOf(id) === index)
      .join(', '),
    'Assignee Names': [
      ...(ticket.assignees?.groups?.map((group) =>
        group?.members.map((member) => member.name)
      ) || []),
      ...(ticket.assignees?.usersOutsideOfGroups?.map((user) => user?.name) ||
        []),
    ]
      .filter((name, index, self) => self.indexOf(name) === index)
      .join(', '),
    'Assignee Group Ids': [
      ...(ticket.assignees?.groups?.map((group) => group?.id) || []),
    ]
      .filter((id, index, self) => self.indexOf(id) === index)
      .join(', '),
    'Assignee Group Names': [
      ...(ticket.assignees?.groups?.map((group) => group?.name) || []),
    ]
      .filter((name, index, self) => self.indexOf(name) === index)
      .join(', '),
    'Approver User Ids Inclusive Of Group Members':
      [
        ...(ticket.approvers?.userOrGroup?.__typename === 'User'
          ? [ticket.approvers?.userOrGroup?.id]
          : ticket.approvers?.userOrGroup?.__typename === 'Group'
          ? [
              ...(ticket.approvers?.userOrGroup?.members?.map(
                (member) => member?.id || ''
              ) || []),
            ]
          : []),
      ]
        .filter((id, index, self) => self.indexOf(id) === index)
        .join(', ') || '',
    'Approver Names':
      [
        ...(ticket.approvers?.userOrGroup?.__typename === 'User'
          ? [ticket.approvers?.userOrGroup?.name]
          : ticket.approvers?.userOrGroup?.__typename === 'Group'
          ? [
              ...(ticket.approvers?.userOrGroup?.members?.map(
                (member) => member?.name || ''
              ) || []),
            ]
          : []),
      ]
        .filter((name, index, self) => self.indexOf(name) === index)
        .join(', ') || '',
    'Approver Group Ids':
      [
        ...(ticket.approvers?.userOrGroup?.__typename === 'Group'
          ? [ticket.approvers?.userOrGroup?.id]
          : []),
      ]
        .filter((id, index, self) => self.indexOf(id) === index)
        .join(', ') || '',
    'Created At': ticket.createdAt || '',
    'Updated At': ticket.updatedAt || '',
    'Meeting Service Name': ticket.meetingService?.name || '',
    'Meeting Service Category': ticket.meetingService?.category?.name || '',
    'Event Id': ticket.regardsEventAtSpace?.event?.id || '',
    'Event Title': ticket.regardsEventAtSpace?.event?.title || '',
    'Event Start Time': ticket.regardsEventAtSpace?.eventStart || '',
    'Event End Time': ticket.regardsEventAtSpace?.eventEnd || '',
    'Space Id': ticket.regardsEventAtSpace?.space?.id || '',
    'Space Name': ticket.regardsEventAtSpace?.space?.name || '',
    'Building Id': ticket.regardsEventAtSpace?.space?.locationId || '',
    'Building Name': '',
    'Floor Id': ticket.regardsEventAtSpace?.space?.levelId || '',
    'Floor Name': '',
    ...answersFormatted,
    'Formatted Total Price': ticket.totalPrice
      ? formatCurrency(ticket.totalPrice)
      : '',
  };
};
