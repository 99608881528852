import { Avatar } from '@robinpowered/ui-kit';
import UserSolid from '@robinpowered/ui-kit-icons/UserSolid';

type Props = {
  name?: string | null;
  avatar?: string | null;
};

// Used for appropriate avatar fallbacks with a robin user since
// ant does not handle it gracefully
export const RobinAvatar = ({ avatar, name }: Props) => {
  return (
    <>
      {avatar ? (
        <Avatar size={'small'} src={avatar} name={name || ''}>
          <UserSolid />
        </Avatar>
      ) : name ? (
        <Avatar size={'small'} name={name} />
      ) : (
        <Avatar size={'small'} icon={<UserSolid />} />
      )}
    </>
  );
};
